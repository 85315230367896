<template>
  <section class="box-height">
    <!-- 表单 -->
    <div class="box-title" style="padding: 20px 0; border: 1px #e5e5e5 solid">
      <el-row :gutter="24" style="position: static">
        <el-col>
          <el-button
            type="default"
            plain
            size="mini"
            style="margin-left: 10px"
            @click="addNew"
          >
            <i class="el-icon-plus"></i> 创建新任务
          </el-button>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :data="ListData"
            class="tabBorder custor"
            :header-row-style="{ height: '36px', margin: '0' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="jobName"
              label="任务名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="beanName"
              label="任务实例"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="methodName"
              label="执行方法"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="cronExpression"
              label="CRON表达式"
              align="left"
              :formatter="cronFormatter"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="parameter"
              label="参数"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="description"
              label="备注"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="status"
              label="状态"
              align="left"
              :formatter="statusFormatter"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="300"
            >
              <template slot-scope="scope">
                <span
                  v-if="!scope.row.inoperable && scope.row.status == 0"
                  @click="pause(scope.row)"
                  class="tabHref"
                  >暂停</span
                >
                <el-divider
                  v-if="!scope.row.inoperable && scope.row.status == 0"
                  direction="vertical"
                ></el-divider>
                <span
                  v-if="!scope.row.inoperable && scope.row.status == -1"
                  @click="resume(scope.row)"
                  class="tabHref"
                  >恢复</span
                >
                <el-divider
                  v-if="!scope.row.inoperable && scope.row.status == -1"
                  direction="vertical"
                ></el-divider>
                <span @click="handleEdit(scope.row)" class="tabHref">编辑</span>
                <el-divider direction="vertical"></el-divider>
                <span @click="handleDel(scope.row)" class="tabHref">删除</span>
                <el-divider direction="vertical"></el-divider>
                <span
                  @click="manualRun(scope.row)"
                  class="tabHref"
                  v-if="scope.row.repeated"
                  >手动执行</span
                >
                <el-divider direction="vertical"></el-divider>
                <span @click="showhistory(scope.row)" class="tabHref"
                  >执行历史</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>

    <el-drawer
      :visible.sync="historyDrawer"
      direction="ttb"
      :modal="false"
      append-to-body
      custom-class="drawerClass"
    >
      <div class="drawerTitle" slot="title">执行历史</div>
      <div style="width: 99%">
        <el-row
          :gutter="24"
          style="position: static; margin-left: 15px; margin-right: -5px"
        >
          <el-col>
            <el-button
              type="default"
              plain
              size="mini"
              style="margin: 10px 15px 10px 0"
              @click="delAll"
            >
              <i class="el-icon-delete"></i> 批量删除
            </el-button>
          </el-col>
          <el-table
            :data="historyData"
            class="tabBorder custor"
            :header-row-style="{ height: '36px', margin: '0' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            v-loading="listLoading"
            @selection-change="handleSelectionChange"
          >
            >
            <el-table-column type="selection" width="45"> </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="jobName"
              label="任务名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="beanName"
              label="任务实例"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="methodName"
              label="执行方法"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="cronExpression"
              label="CRON表达式"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="parameter"
              label="参数"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="createdTime"
              label="触发时间"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="status"
              label="执行结果"
              align="left"
            >
              <template slot-scope="scope">
                <i class="el-icon-check" v-if="scope.row.status == 0">正常</i>
                <i class="el-icon-close" v-if="scope.row.status == -1">错误</i>
                <el-popover
                  placement="top-start"
                  trigger="click"
                  v-if="scope.row.status == -1"
                >
                  <div>
                    <pre
                      >{{ scope.row.error }}     
                    </pre>
                  </div>
                  <el-tag
                    type="danger"
                    size="small"
                    slot="reference"
                    class="showhis"
                    >查看</el-tag
                  >
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="times"
              label="耗时(毫秒)"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="center"
              width="80"
              fixed="right"
            >
              <template slot-scope="scope">
                <span @click="handleDelHis(scope.row)" class="tabHref"
                  >删除</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="hisPagination.total > 10 ? false : true"
            v-if="hisPagination.total > 0"
            @size-change="handleHisSizeChange"
            @current-change="handleHisCurrentChange"
            :current-page="hisPagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="hisPagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="hisPagination.total"
          ></el-pagination>
        </el-row>
      </div>
    </el-drawer>
    <!-- 弹框 -->
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="jobForm"
        ref="jobForm"
        :rules="rules"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="任务名称：" prop="jobName">
              <el-input
                v-model.trim="jobForm.jobName"
                maxlength="20"
                placeholder="请不要输入超过20个字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="任务实例：" prop="beanName">
              <el-select
                v-model="jobForm.beanName"
                placeholder="请选择"
                style="width: 100%"
                @visible-change="getJobBeans"
              >
                <el-option
                  v-for="(item, i) in jobBeans"
                  :key="i"
                  :value="item.beanName"
                  @click.native="getMoreParams(item)"
                  >{{ item.jobName + " - " + item.beanName }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="执行方法：" prop="methodName">
              <el-select
                v-model="jobForm.methodName"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, i) in jobMethods"
                  :key="i"
                  :value="item"
                  >{{ item }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否重复执行：" prop="repeated">
              <el-switch v-model="jobForm.repeated"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="CRON表达式："
              prop="cronExpression"
              v-if="jobForm.repeated === true"
            >
              <div style="display: flex">
                <el-input
                  v-model="jobForm.cronExpression"
                  maxlength="20"
                ></el-input>
                <a
                  target="_blank"
                  class="tabHref"
                  rel="noopener noreferrer"
                  href="http://cron.qqe2.com/"
                  style="width: 230px; margin-left: 10px"
                >
                  <i class="el-icon-s-promotion" size="16" />在线cron表达式生成
                </a>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="执行时间："
              prop="scheduledTime"
              v-if="jobForm.repeated === false"
            >
              <el-date-picker
                v-model="jobForm.scheduledTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="scheduledTimeOptions"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="参数：" prop="parameter">
              <el-input
                v-model.trim="jobForm.parameter"
                maxlength="20"
                placeholder="如执行方法不存在参数，请勿填写"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注：" prop="description">
              <el-input
                v-model.trim="jobForm.description"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetForm('jobForm')">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="save('jobForm')"
          :loading="saveloading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getQuartzJobList,
  addQuartzJob,
  editQuartzJob,
  pauseQuartzJob,
  resumeQuartzJob,
  deleteQuartzJob,
  manualRunQuartzJob,
  showQuartzJobHistory,
  deleteQuartzJobHis,
} from "../../api/api";
import { getRequest } from "../../api/http";
export default {
  created() {
    this.loadList();
  },
  destroyed: function () {
    //this.disconnect();
  },
  data() {
    const validateScheduleTime = (rule, value, callback) => {
      let scheduledTimeStr = value.replace(new RegExp("-", "gm"), "/");
      let scheduledLongTime = new Date(scheduledTimeStr).getTime();

      if (scheduledLongTime <= new Date().getTime() + 30000) {
        callback(new Error("执行时间至少为当前时间30秒后"));
      } else {
        callback();
      }
    };
    return {
      selectedHistory: [],
      jobId: "",
      historyDrawer: false,
      modalType: 0,
      jobBeans: [],
      jobMethods: [],
      ListData: [],
      historyData: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      hisPagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      listLoading: false,
      dialogVisible: false,
      saveloading: false,
      scheduledTimeOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },
      rules: {
        jobName: [
          {
            required: true,
            trigger: "blur",
            message: "任务名称不能为空",
          },
        ],
        beanName: [
          {
            required: true,
            trigger: "change",
            message: "任务实例不能为空",
          },
        ],
        methodName: [
          {
            required: true,
            trigger: "change",
            message: "执行方法不能为空",
          },
        ],
        cronExpression: [
          {
            required: true,
            trigger: "blur",
            message: "cron表达式不能为空",
          },
        ],
        scheduledTime: [
          { required: true, message: "请指定执行时间", trigger: "blur" },
          { validator: validateScheduleTime, trigger: "blur" },
        ],
      },
      jobForm: {
        id: "",
        jobName: "",
        beanName: "",
        methodName: "",
        repeated: true,
        cronExpression: "",
        scheduledTime: "",
        parameter: "",
        description: "",
      },
      titName: "",
    };
  },
  methods: {
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      getQuartzJobList({
        pageNumber: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        } else {
          this.listLoading = false;
        }
      });
    },
    loadHistory() {
      this.listLoading = true;
      showQuartzJobHistory(this.jobId, {
        pageNumber: this.hisPagination.pageNo,
        pageSize: this.hisPagination.pageSize,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.historyData = res.result.records;
          this.hisPagination.total = res.result.total;
        } else {
          this.listLoading = false;
        }
      });
    },
    getJobBeans() {
      getRequest("/quartz/job/job-classes").then((res) => {
        if (res.success) {
          this.jobBeans = res.result;
        }
      });
    },
    getMoreParams(obj) {
      this.jobForm.methodName = "";
      this.jobMethods = obj.method;
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleHisSizeChange(val) {
      //pageSize 改变时会触发
      this.hisPagination.pageSize = val;
      this.hisPagination.pageNo = 1;
      this.loadHistory();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    handleHisCurrentChange(val) {
      //currentPage 改变时会触发
      this.hisPagination.pageNo = val;
      this.loadHistory();
    },

    handleClose() {
      this.resetForm("jobForm");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    handleEdit: function (v) {
      this.modalType = 1;
      this.titName = "编辑标签";
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      getRequest("/quartz/job/job-classes").then((res) => {
        if (res.success) {
          this.jobBeans = res.result;
          this.jobBeans
            .filter((val) => val.beanName == v.beanName)
            .forEach((val) => (this.jobMethods = val.method));
        } else {
          this.jobMethods = [v.methodName];
        }
        let str = JSON.stringify(v);
        let data = JSON.parse(str);
        this.jobForm = data;
        this.dialogVisible = true;
      });
    },
    addNew: function () {
      this.dialogVisible = true;
      this.titName = "添加任务";
      this.modalType = 0;
      this.jobBeans = [];
      this.jobMethods = [];
    },
    handleDel: function (v) {
      this.$confirm("请确认是否删除任务 " + v.jobName + " ？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteQuartzJob(v.id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleDelHis: function (v) {
      this.$confirm("您确认要删除此条任务执行历史？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteQuartzJobHis(v.id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadHistory();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveloading = true;
          if (this.modalType == 0) {
            addQuartzJob(this.jobForm).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogVisible = false;
                this.resetForm("jobForm");
                this.loadList();
              }
              this.saveloading = false;
            });
          } else {
            editQuartzJob(this.jobForm).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogVisible = false;
                this.resetForm("jobForm");
                this.loadList();
              }
              this.saveloading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    pause: function (v) {
      this.$confirm("您确认要暂停任务 " + v.jobName + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          pauseQuartzJob(v.id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    resume: function (v) {
      this.$confirm("您确认要恢复任务 " + v.jobName + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          resumeQuartzJob(v.id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    manualRun: function (v) {
      this.$confirm("您确认要立即执行一次任务：" + v.jobName + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          manualRunQuartzJob(v.id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    showhistory(v) {
      this.historyData = [];
      this.jobId = v.id;
      this.historyDrawer = true;
      this.loadHistory();
    },
    statusFormatter(row, column) {
      if (row.status === 0) {
        return (
          <el-tag type="success" size="small">
            执行中
          </el-tag>
        );
      }
      return (
        <el-tag type="danger" size="small">
          已停止
        </el-tag>
      );
    },
    cronFormatter(row, column) {
      if (row.repeated) {
        return row.cronExpression;
      } else {
        return row.scheduledTime;
      }
    },
    showError(v) {
      alert(v.error);
    },
    handleSelectionChange(val) {
      this.selectedHistory = val;
    },
    delAll() {
      if (this.selectedHistory.length <= 0) {
        this.$message.warning("您还未选择要删除的数据");
        return;
      }
      this.$confirm("您确认要删除所选的数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = "";
          this.selectedHistory.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          deleteQuartzJobHis(ids).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadHistory();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>
<style lang='less' scoped>
.showhis {
  margin-left: 8px;
  cursor: pointer;
}
.el-icon-check {
  font-size: 10px;
  color: #67c23a;
}
.el-icon-close {
  font-size: 10px;
  color: #f56c6c;
}
</style>

